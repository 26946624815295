import {AscDesc, GenericService, Result, ResultType} from '@/shared/xhttp';
import {Livraison} from '@/models';
import caller from "@/shared/caller";

export class LivraisonService extends GenericService<Livraison> {

    public async putFacture(livraison: Livraison, idfacture: string) {
		await this.put(`/${livraison.id}/putFacture/${idfacture}`);
	}

    public async resetFacture(livraison: Livraison) {
		await this.put(`/${livraison.id}/resetFacture`);
	}
}
