import {XHTTP} from '@/shared/xhttp';
import {FactureService} from '@/xhttp';
import {DateHelper} from "@/shared/utils";
import {Tiers} from "./Tiers";
import {Chauffeur} from '@/models/Chauffeur';
import {CubageLivraison} from '@/models/CubageLivraison';
import {SuiviReglementClient} from '@/models/SuiviReglementClient';

@XHTTP(FactureService, '/factures')
export class Facture {

	private _id: number;
	private _date: Date = new Date();
	private _scierie: Tiers;
	private _numfacture: string; 
	private _montant: number = 0;
	private _payer: boolean = false;
	private _cubageLivraisons: CubageLivraison[] = [];
	private _suiviReglementClients: SuiviReglementClient[] = [];
	
	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get date(): Date {
		return this._date;
	}

	public get scierie(): Tiers {
		return this._scierie;
	}

	public get montant(): number {
		return this._montant;
	}

	public get payer(): boolean {
		return this._payer;
	}

	public get numfacture(): string {
		return this._numfacture;
	}

	public get cubageLivraisons(): CubageLivraison[] {
		return this._cubageLivraisons;
	}

	public get suiviReglementClients(): SuiviReglementClient[] {
		return this._suiviReglementClients;
	}

	/////////
	// Add //
	/////////

	/////////////
	// Setters //
	/////////////

	public set id(value: number) {
		this._id = value;
	}

	public set date(value: Date) {
		this._date = value;
	}

	public set montant(value: number) {
		this._montant = value;
	}

	public set numfacture(value: string) {
		this._numfacture = value;
	}

	public set payer(value: boolean) {
		this._payer = value;
	}

	public set scierie(value: Tiers) {
		this._scierie = value;
	}
	
	public set cubageLivraisons(value: CubageLivraison[]) {
		this._cubageLivraisons = value;
	}

	public set suiviReglementClients(value: SuiviReglementClient[]) {
		this._suiviReglementClients = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			date    : this._date ? DateHelper.toLocalizedString(this._date) : null,
			montant	: this._montant,
			numfacture	: this._numfacture,
			payer	: this._payer,
			scierie         : this._scierie ? this._scierie.id : null,
			cubageLivraisons: this._cubageLivraisons ? this._cubageLivraisons.map(r => r.toJSON()) : [],
			suiviReglementClients: this._suiviReglementClients ? this._suiviReglementClients.map(r => r.toJSON()) : [],
		};
	}

	public fromJSON(data: any): this {
		this._id           = data.id
		this._date         = data.date ? DateHelper.fromLocalizedString(data.date) : null;
		this._montant 		   = data.montant;
		this._numfacture 		   = data.numfacture;
		this._payer 		   = data.payer;
		this._scierie      = data.scierie ? (new Tiers).fromJSON(data.scierie) : null;
		this._cubageLivraisons    = data.cubageLivraisons ? data.cubageLivraisons.map(r => (new CubageLivraison()).fromJSON(r)) : [];
		this._suiviReglementClients    = data.suiviReglementClients ? data.suiviReglementClients.map(r => (new SuiviReglementClient()).fromJSON(r)) : [];
		return this;
	}
}
