import {Facture} from '@/models';

export default [
	{
		path: '/factures',
		name: 'factures',
		meta: {
			title: 'Liste des factures',
			granted: [ 'API_GET_FACTURE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Factures.vue')
	},
	{
		path: '/facture/show/:id',
		name: 'facture-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Facture/Show.vue'),
				class: Facture
			}),
			editRoute: { name: 'facture-edit' },
		},
		meta: {
			title: 'Détails d\'une facture',
			granted: [ 'API_GET_FACTURE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/facture/edit/:id',
		name: 'facture-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Facture/Edit.vue'),
				class: Facture
			}),
		},
		meta: {
			title: 'Edition d\'une facture',
			granted: [ 'API_PUT_FACTURE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
