import {GenericService} from '@/shared/xhttp';
import {Facture} from '@/models';

export class FactureService extends GenericService<Facture> {

    public async putMarkPaid(facture: Facture) {
		await this.put(`/${facture.id}/mark-paid`);
	}

}
